<script>
    import { page } from '$app/stores';
	import PndrLogo from '$lib/images/PndrLogo.svelte';

    let project = '';
    let bughref = '/contact?s=Signaler un bug&p=Site pndr'

    if ($page.url.pathname.includes("/go")) {
        project = $page.url.pathname.split("/")[2];
        bughref = `/contact?s=Signaler un bug&p=${project}`
    }

</script>

<nav class="navbar bg-base-100 min-h-0 py-0">
    <div class="container mx-auto max-w-5xl">
    <div class="flex-1">
        <a href="/" class="btn btn-xs btn-ghost lg:px-4 text-xl hover:bg-transparent mt-1" aria-label="Se rendre sur la page d'accueil de pndr"><PndrLogo additionalClasses="w-14 inline-block fill-current" secondFill="fill-primary" /></a>
    </div>
    <div class="flex-none">
        <menu class="menu menu-xs menu-horizontal px-1 gap-x-6 lg:px-4">
            <li><a href={encodeURI(bughref)}><i class="fa-solid fa-bug"></i> Signaler un bug</a></li>
            <li><a href="/contact">Contact</a></li>
        </menu>
    </div>
    </div>
</nav>