<script>
	import { Footer, Subheader } from '$lib/components';
</script>

<svelte:head>
	<title>pndr</title>
	<meta name="description" content="Live events and music industry digital services since 2021" />
</svelte:head>

<div class="flex flex-col min-h-dvh">
	
	<Subheader />
	
	<slot />

	<Footer />

</div>